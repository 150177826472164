@keyframes bounce-loader {
  to {
    opacity: 0.1;
    transform: translateY(-1rem);
  }
}

.bounce-loading {
  display: flex;
  justify-content: center;
}

.bounce-loading > div {
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bounce-loader 0.6s infinite alternate;
}

.bounce-loading > div:nth-child(1) {
  background: #ff4842;
}

.bounce-loading > div:nth-child(2) {
  animation-delay: 0.15s;
  background: #00ab55;
}

.bounce-loading > div:nth-child(3) {
  animation-delay: 0.3s;
  background: #ffc107;
}

.bounce-loading > div:last-child {
  animation-delay: 0.45s;
  background: #1890ff;
}
